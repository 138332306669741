/* !
 * index.js v1.0.0
 * Copyright, Connecty Inc.
 */
((FUNCTIONS) => {
	const a = 'is-active';
	const v = 'is-visible';

	const mainVisSlider = () => {
		if(document.querySelectorAll('.top-slider01').length) {
			const topslider01 = new Swiper('.top-slider01', {
				slidesPerView: 1,
				spaceBetween: 0,
				loop: true,
				loopAdditionalSlides: 0,
				speed: 1000,
				autoplay: {
					delay: 4000,
					disableOnInteraction: false,
				},
				pagination: {
					el: '.top-slider01-control .swiper-pagination',
					clickable: true,
				}
			});
			document.querySelector('.top-slider01-control-button').addEventListener('click', (e) => {
				if(!(e.currentTarget.classList.contains(a))) {
					e.currentTarget.ariaLabel = '停止';
					e.currentTarget.classList.add(a);
					topslider01.autoplay.start();
				} else {
					e.currentTarget.ariaLabel = '再生';
					e.currentTarget.classList.remove(a);
					topslider01.autoplay.stop();
				}
			});
		}
	};

	const topWebinarSlider = () => {
	    if (window.innerWidth <= 1024 && document.querySelectorAll('.top-slider02').length) {
	        const topslider02 = new Swiper('.top-slider02', {
	            slidesPerView: 1,
	            spaceBetween: 30,
	            loop: true,
	            loopAdditionalSlides: 0,
	            speed: 1000,
	            autoplay: {
	                delay: 4000,
	                disableOnInteraction: false,
	            },
	            pagination: {
	                el: '.top-slider02-control .swiper-pagination',
	                clickable: true,
	            }
	        });
	        document.querySelector('.top-slider02-control-button').addEventListener('click', (e) => {
	            if (!(e.currentTarget.classList.contains(a))) {
	                e.currentTarget.ariaLabel = '停止';
	                e.currentTarget.classList.add(a);
	                topslider02.autoplay.start();
	            } else {
	                e.currentTarget.ariaLabel = '再生';
	                e.currentTarget.classList.remove(a);
	                topslider02.autoplay.stop();
	            }
	        });
	    }
	};

	const topRemoveWebinar = () => {
		// ニュースリストのクラスを取得
		let newsListElements = document.querySelectorAll('.rt_bn_news_list_webinars');

		// 現在の日付を取得
		let currentDate = new Date();

		// ニュースリスト要素をループ
		newsListElements.forEach(function(newsListElement) {
		    // ニュースリスト内の「webinars-box1」に「_off」クラスを追加する関数
		    const addOffClass = () => {
		        let webinarBox = newsListElement.querySelector('.webinars-box1');
		        if (webinarBox) {
		            webinarBox.classList.add('_off');
		        }
		    };

		    // ニュースリスト内に「rt_cf_n_event_status_jp_4」クラスがある場合
		    if (newsListElement.querySelector('.rt_cf_n_event_status_jp_4')) {
		        addOffClass();
		    }

		    // ニュースリスト内の日付要素を取得
		    let dateElements = newsListElement.querySelectorAll('.rt_cf_n_end_date_jp');
		    
		    dateElements.forEach(function(dateElement) {
		        // 日付文字列を取得
		        let dateString = dateElement.textContent || dateElement.innerText;

		        // 日付文字列を解析してDateオブジェクトに変換
		        let datePattern = /(\d{4})年(\d{2})月(\d{2})日 (\d{2}):(\d{2})/;
		        let match = dateString.match(datePattern);
		        if (match) {
		            let year = parseInt(match[1], 10);
		            let month = parseInt(match[2], 10) - 1; // JavaScriptの月は0ベース
		            let day = parseInt(match[3], 10);
		            let hour = parseInt(match[4], 10);
		            let minute = parseInt(match[5], 10);
		            let endDate = new Date(year, month, day, hour, minute);
		            console.log(endDate);

		            // 日付が現在の日付を過ぎているか確認
		            if (endDate < currentDate) {
		                addOffClass();
		            }
		        }
		    });
		});

		// 「webinars-box1」クラスがない場合、親要素の「top-webinar」クラス内の要素を削除
	   	let topWebinar = document.querySelector(".top-webinar");

	    if (topWebinar) {
	        // .webinars-box1 クラスが .top-webinar 内に存在するか確認
	        let webinarsBox = topWebinar.querySelector(".webinars-box1");
	        
	        if (!webinarsBox) {
	            // 存在しない場合、.top-webinar 要素を削除
	            topWebinar.remove();
	        }
	    }
	};

	mainVisSlider();
	topWebinarSlider();
	topRemoveWebinar();


})(window.FUNCTIONS);
